import {remoteForm, type Kicker} from '@github/remote-form'
import {showGlobalError} from '../behaviors/ajax-error'
import {TemplateInstance} from '@github/template-parts'

function fetchPoll(url: string, pollIntervalMs: number) {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      const request = new Request(url)
      request.headers.append('X-Requested-With', 'XMLHttpRequest')
      const response = await fetch(request)
      if (response.status === 200) {
        // Resource is ready.
        resolve(response)
      } else if (response.status === 202) {
        // Resource isn't ready so we're gonna retry.
        resolve(fetchPoll(url, pollIntervalMs))
      } else {
        // Something unexpected happened.
        reject(response)
      }
    }, pollIntervalMs) // Poll the endpoint every intervalMs
  })
}

/**
 * Create a new flash element and inject it into the dom,
 * since server-side flashes only populate after navigation
 */
function flashMessage(message: string, type = 'notice') {
  const template = new TemplateInstance(document.querySelector<HTMLTemplateElement>('.js-flash-template')!, {
    className: `flash-${type}`,
    message,
  })
  const node = document.importNode(template, true)
  const flashContainer = document.querySelector<HTMLElement>('#js-flash-container')!
  for (const child of flashContainer.children) {
    if (!child.classList.contains('js-flash-template')) {
      flashContainer.removeChild(child)
    }
  }
  flashContainer.appendChild(node)
}

async function generateConsumption(loadingClass: string, btnClass: string, wants: Kicker, pollIntervalMs: number) {
  const loadingState = document.querySelector<HTMLElement>(loadingClass)!
  const btn = document.querySelector<HTMLElement>(btnClass)!
  const warning = document.getElementById('truncate-warning')!

  // Show/hide loading state. Set to `true` to hide btn and show loading state.
  function loading(toggle: boolean) {
    if (btn) {
      btn.hidden = toggle
    }
    loadingState.hidden = !toggle
  }

  loading(true)
  if (warning != null) {
    warning.hidden = true
  }

  let response
  try {
    response = await wants.json()
  } catch (error) {
    loading(false)
    showGlobalError()
    return
  }

  const data = response.json

  if (data.notify) {
    // Show message but don't stop polling. The file will eventually download if the user doesn't leave the page.
    flashMessage(data.notify)
  }

  try {
    await fetchPoll(data.job_url, pollIntervalMs)
  } catch (error) {
    loading(false)
    showGlobalError()
    return
  }

  loading(false)
  window.location = data.export_url
}

remoteForm('.js-license-usage-download-form', async function (form, wants) {
  await generateConsumption('.js-license-usage-generating', '.js-license-usage-download-btn', wants, 2000)
})
