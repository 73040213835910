import type AutocompleteElement from '@github/auto-complete-element'
import type DetailsDialogElement from '@github/details-dialog-element'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

function businessTwoFactorInput(): HTMLInputElement {
  return document.querySelector<HTMLInputElement>('.js-business-enable-two-factor')!
}

function enablingTwoFactor(): boolean {
  const twoFactorCurrentlyEnabled =
    document.querySelector<HTMLInputElement>('.js-business-two-factor-currently-enabled')!.value === '0'
  return businessTwoFactorInput().checked && twoFactorCurrentlyEnabled
}

// bypass showing the two-factor confirmation dialog if we're not enabling two-factor
on('click', '.js-business-two-factor-submit-button', function (event) {
  if (!enablingTwoFactor()) {
    event.preventDefault()
  }
})

on('change', '.js-repo-types-allowed-radio', () => {
  const repoTypeCheckboxes = document.querySelectorAll<HTMLInputElement>('.js-repo-type-creation-checkbox')
  const repoTypeLabels = document.querySelectorAll<HTMLElement>('.js-repo-type-creation-label')
  for (const checkbox of repoTypeCheckboxes) {
    checkbox.disabled = false
  }
  for (const label of repoTypeLabels) {
    label.classList.remove('color-fg-muted')
  }
})

on('change', '.js-repo-types-disallowed-radio', () => {
  const repoTypeCheckboxes = document.querySelectorAll<HTMLInputElement>('.js-repo-type-creation-checkbox')
  const repoTypeLabels = document.querySelectorAll<HTMLElement>('.js-repo-type-creation-label')
  for (const checkbox of repoTypeCheckboxes) {
    checkbox.disabled = true
    checkbox.checked = false
  }
  for (const label of repoTypeLabels) {
    label.classList.add('color-fg-muted')
  }
})

on('change', '.js-provisioning-enabled-checkbox', () => {
  const provisioningEnabledPreviously = document.querySelector<HTMLInputElement>(
    '[name="business_saml_provider_provisioning_enabled_previously"]',
  )!

  if (provisioningEnabledPreviously.value === '0') {
    // setting disabled on the server, nothing to do yet.
    return
  }

  const userProvisioningCheckbox = document.querySelector<HTMLInputElement>('.js-provisioning-enabled-checkbox')!
  const deprovisioningCheckboxes = document.querySelectorAll<HTMLInputElement>(
    '.js-disable-when-user-provisioning-disabled-checkbox',
  )

  if (userProvisioningCheckbox.checked) {
    for (const checkbox of deprovisioningCheckboxes) {
      const previousValueInput = document.querySelector<HTMLInputElement>(`[name='${checkbox.id}_previously']`)!
      const formCheckbox = checkbox.closest<HTMLElement>('.js-deprovisioning-checkbox')!

      formCheckbox.classList.remove('checkbox-disabled')
      checkbox.removeAttribute('disabled')

      if (previousValueInput.value === '1') {
        // restore state for the last known server value
        checkbox.checked = true
      } else {
        checkbox.removeAttribute('checked')
      }
    }
  } else {
    // un-check, disable
    for (const checkbox of deprovisioningCheckboxes) {
      checkbox.setAttribute('disabled', 'disabled')
      checkbox.checked = false
      checkbox.closest<HTMLElement>('.js-deprovisioning-checkbox')!.classList.add('checkbox-disabled')
    }
  }
})

// view updates when user switches enterprise package setting
on('change', '.js-enterprise-package-settings-radio', event => {
  const elements = getPackagesElements()
  const target = event.currentTarget

  if (target == null) {
    return
  }

  const packageSettingValue = target.getAttribute('data-package-setting-value')

  for (const checkbox of elements.packageTypeCheckboxes) {
    if (packageSettingValue === 'managed') {
      checkbox.hidden = false
    } else {
      checkbox.hidden = true
    }
  }

  elements.packageTypeList.hidden = packageSettingValue === 'disabled'
  elements.packageSettingsManagedWarning.hidden = packageSettingValue !== 'managed'
  elements.packageSettingsDisabledWarning.hidden = packageSettingValue !== 'disabled'
  elements.updateSettingsButton.disabled = false
})

on('change', '.js-package-type-checkbox', () => {
  const elements = getPackagesElements()
  elements.updateSettingsButton.disabled = false
})

function getPackagesElements() {
  const packageTypeCheckboxes = document.querySelectorAll<HTMLInputElement>('.js-package-type-checkbox')
  const packageTypeList = document.querySelector<HTMLElement>('.js-package-types-list')!
  const packageSettingsManagedWarning = document.querySelector<HTMLElement>(
    '.js-enterprise-package-settings-managed-warning',
  )!
  const updateSettingsButton = document.querySelector<HTMLButtonElement>('.js-update-enterprise-package-settings')!
  const packageSettingsDisabledWarning = document.querySelector<HTMLElement>(
    '.js-enterprise-package-settings-disabled-warning',
  )!

  return {
    packageTypeCheckboxes,
    packageTypeList,
    packageSettingsManagedWarning,
    updateSettingsButton,
    packageSettingsDisabledWarning,
  }
}

function toggleBillingInformationEditForm(shown: boolean) {
  const editButtons = document.querySelectorAll<HTMLElement>('.js-billing-settings-billing-information-edit-button')
  for (const editButton of editButtons) {
    editButton.hidden = shown
  }

  const detailElements = document.querySelectorAll<HTMLElement>('.js-billing-settings-billing-information')
  for (const detailElement of detailElements) {
    detailElement.hidden = shown
  }

  const editFormContainer = document.querySelector<HTMLElement>(
    '.js-billing-settings-billing-information-form-container',
  )
  if (editFormContainer) {
    editFormContainer.hidden = !shown
  }
}

on('click', '.js-billing-settings-billing-information-edit-button', function () {
  toggleBillingInformationEditForm(true)
})

on('click', '.js-billing-settings-billing-information-cancel-button', function () {
  toggleBillingInformationEditForm(false)
})

function toggleBillingInvoiceEmailPreferenceForm(shown: boolean) {
  const editButtons = document.querySelectorAll<HTMLElement>(
    '.js-billing-settings-billing-invoice-email-preference-edit-button',
  )
  for (const editButton of editButtons) {
    editButton.hidden = shown
  }

  const editFormContainer = document.querySelector<HTMLElement>(
    '.js-billing-settings-billing-invoice-preference-form-container',
  )
  if (editFormContainer) {
    editFormContainer.hidden = !shown
  }
}

on('click', '.js-billing-settings-billing-invoice-email-preference-edit-button', function () {
  toggleBillingInvoiceEmailPreferenceForm(true)
})

on('change', '.js-pat-expiration-limit', event => {
  const target = event.target as HTMLInputElement
  if (target.value === 'custom') {
    document.getElementById('custom-pat-expiration-limit-wrapper')?.removeAttribute('hidden')
  } else {
    document.getElementById('custom-pat-expiration-limit-wrapper')?.setAttribute('hidden', 'true')
  }
})
